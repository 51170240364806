export interface Section {
  type: SectionTypes;
  visible: boolean;
}

export enum SectionTypes {
  DESCRIPTION = 'DESCRIPTION',
  TITLE_TAGLINE = 'TITLE_TAGLINE',
  CONTACT = 'CONTACT',
  DETAILS = 'DETAILS',
  POLICY = 'POLICY',
  SCHEDULING = 'SCHEDULING',
}

export enum AlignmentOptions {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export const enum SettingsTab {
  Manage = 'Manage',
  Layout = 'Layout',
  Sections = 'Sections',
  Design = 'Design',
}
